import { datadogRum } from '@datadog/browser-rum'
import dayjs from 'dayjs'
import { isArray } from 'lodash'
import { useContext, useEffect } from 'react'

import { quoteId } from '../cache'
import {
  QuoteDocument,
  QuoteQuery,
  QuoteQueryVariables,
  UpdateQuoteMutationOptions,
  useUpdateQuoteMutation,
} from '../generated/graphql'
import { ProviderContext } from '../Provider'
import { handleShowChurchill } from '../utils/handleShowChurchill'
import { onlyImmobiliserIsIdScore } from '../utils/onlyImmobiliserIsIdScore'
import { quoteUpdateQueue } from './useQueueQuoteUpdates'

enum KickoutReason {
  'obd-failed',
}

const immobiliserCheck = (immobilisers: any, isAllowListed: boolean): KickoutReason | null => {
  if (immobilisers.find((i: { type: string }) => i.type === 'checkOBDorConnectedCar') && !isAllowListed) {
    if (immobilisers?.length === 1) {
      return KickoutReason['obd-failed']
    }
    if (immobilisers?.length > 1) {
      const otherImmobilisers = immobilisers.filter((i: { type: string }) => i.type !== 'checkOBDorConnectedCar')
      if (onlyImmobiliserIsIdScore(otherImmobilisers)) return KickoutReason['obd-failed']
    }
  }
  return null
}

const UpdateQuoteMutation = () => {
  const { state, setPricingState, simpleStateUpdater, setIsFetching } = useContext(ProviderContext)
  const [updateQuote, updateQuoteState] = useUpdateQuoteMutation({
    onCompleted: data => {
      datadogRum.addAction('Quote updated')
      const quote = data?.updateQuote.quote
      if (!quote) {
        datadogRum.addError('Quote not found', { quoteId: state.quoteId })
        window.location.href = '/sorry'
      } else if (!quote.pricing) {
        simpleStateUpdater([
          { state: 'quoteId', value: quote.id },
          { state: 'isTrackerless', value: quote?.details.policy?.isTrackerless! },
        ])
      } else if (!quote.details?.policy?.inception_date) {
        datadogRum.addError('No inception date', { quoteId: state.quoteId })
        if (window.location.pathname !== '/details/cover') {
          window.location.href = window.location.pathname.includes('/details') ? 'cover' : 'details/cover'
        }
      } else if (dayjs(quote.details?.policy?.inception_date!).isBefore(dayjs(), 'day')) {
        datadogRum.addAction('Inception date in the past', { quoteId: state.quoteId })
        if (window.location.pathname !== '/details/cover') {
          window.location.href = window.location.pathname.includes('/details') ? 'cover' : 'details/cover'
        }
      } else if (quote.pricing) {
        simpleStateUpdater([{ state: 'showChurchill', value: handleShowChurchill(quote.details.sid || '') }])
        const excludedPageNames = ['car', 'driver']
        const shouldICheckImmobiliser = !excludedPageNames.includes(state.pageName)

        const specialImmobiliser = shouldICheckImmobiliser
          ? immobiliserCheck(quote.pricing?.immobiliser, quote.isAllowListed)
          : null

        if (specialImmobiliser === KickoutReason['obd-failed']) window.location.href = '/sorry/obd-fit'

        if (
          shouldICheckImmobiliser &&
          typeof specialImmobiliser !== 'number' &&
          !quote.pricing.isValid &&
          !quote.isAllowListed &&
          !onlyImmobiliserIsIdScore(quote.pricing?.immobiliser)
        ) {
          datadogRum.addAction('Invalid quote', {
            quoteId: quote.id,
            isValid: quote.pricing?.isValid,
            isAllowListed: quote.isAllowListed,
            immobiliser: quote.pricing?.immobiliser,
          })
          window.location.href = '/sorry'
        } else {
          setPricingState(quote)
          simpleStateUpdater([
            { state: 'quoteId', value: quote.id },
            { state: 'isTrackerless', value: quote?.details.policy?.isTrackerless! },
            { state: 'obdFit', value: quote?.details.vehicle.obd_fit },
            {
              state: 'confirmPageConfirmed',
              value: quote?.details?.site?.confirmed || false,
            },
          ])
        }
      }
    },
    onError: e => {
      datadogRum.addError(e)
      window.location.href = '/sorry'
    },
    update: (store, { data }) => {
      const quote = data?.updateQuote.quote
      if (!quote) return
      store.writeQuery<QuoteQuery, QuoteQueryVariables>({
        query: QuoteDocument,
        variables: { id: quote.id },
        data: { quote: quote },
      })
      quoteId(quote.id)
    },
  })

  useEffect(() => {
    setIsFetching(updateQuoteState.loading)
  }, [updateQuoteState.loading])

  const updateQuoteMW = (args: UpdateQuoteMutationOptions) => {
    if (!args.variables) return
    if (!args.variables.id) window.location.href = 'details/car'
    if (!args.variables.actions) args.variables.actions = []
    if (!isArray(args.variables.actions)) args.variables.actions = [args.variables.actions]
    const queuedActions = quoteUpdateQueue()
    args.variables.actions = args.variables.actions.concat(queuedActions)
    quoteUpdateQueue([])
    return updateQuote(args)
  }

  return { updateQuote: updateQuoteMW, ...updateQuoteState }
}

export const updateQuoteMutation = UpdateQuoteMutation
