import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const GenericStyles = styled.div`
  .hero-heading {
    color: ${theme.primaryColours.aubergine.main};
    margin: ${theme.grid.spacing.base}px 0;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin: ${theme.grid.spacing.l}px 0;
    }
  }

  .component-heading {
    color: ${theme.primaryColours.aubergine.main};
    margin: ${theme.grid.spacing.s}px 0 ${theme.grid.spacing.xs}px 0;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin: ${theme.grid.spacing.md}px 0 ${theme.grid.spacing.s}px 0;
    }
  }

  .get-in-touch-wrapper {
    margin-bottom: ${theme.grid.spacing.s}px;
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      margin-bottom: ${theme.grid.spacing.md}px;
    }
  }

  .component-spacing {
    margin-top: ${theme.grid.spacing.s}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-top: ${theme.grid.spacing.md}px;
    }
  }

  &.blur {
    backdrop-filter: blur(2px);
  }
`
